import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setUser } from '../../actions-index';
import SignIn from '../components/SignIn';

const mapStateToProps = (state) => {
    const { authentication } = state
    const { status = '' } = authentication

    return {
        status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: () => dispatch(setUser())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
