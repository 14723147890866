import Auth from "@aws-amplify/auth";
import {
  HOUR_INTERVAL_RANGE,
  HOURS_IN_A_DAY,
  FEET_IN_A_MILE,
} from "./constants";

export function redirectToLogOut() {
  Auth.signOut();
}

export function redirectToLogIn() {
  const { oauth } = Auth.configure();

  Auth.federatedSignIn({ customProvider: oauth.idp });
}

export const createHourInterval = (overrideRange) => {
  const range = overrideRange || HOUR_INTERVAL_RANGE;
  const currentHour = new Date().getHours();
  const beginning = currentHour - range > 0 ? currentHour - range : 0;
  const end =
    currentHour + range < HOURS_IN_A_DAY ? currentHour + range : HOURS_IN_A_DAY;

  return [beginning, end];
};

export const convertFeetToMiles = (distanceInFeet) => {
  return (distanceInFeet / FEET_IN_A_MILE).toFixed(2);
};

export const encodeGoogleMapUrl = (latitude, longitude) => {
  if (!latitude || !longitude) return;

  return encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  );
};
export async function GetCurrentDate(separator = "-") {
  let newDate = new Date();
  let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}
export async function GetCurrentDateTime(separator = "-") {
  // let date_create = moment().format("MM-DD-YYYY hh:mm:ss");
  let newDate = new Date().toLocaleString() + "";
  return newDate.toString();
  // let newDate = new Date();
  // let date = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
  // let month = newDate.getMonth() + 1;
  // let year = newDate.getFullYear();

  // return `${year}${separator}${
  // 	month < 10 ? `0${month}` : `${month}`
  // }${separator}${date}`;
}
export function StringToBoolean(value) {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}
export const isLogin = (user) => {
  if (user && user.status === "success") {
    return true;
  }
  return false;
};
export function generateRowId(shardId /* range 0-64 for shard/slot */) {
  var CUSTOMEPOCH = 1300000000000;
  var ts = new Date().getTime() - CUSTOMEPOCH; // limit to recent
  var randid = Math.floor(Math.random() * 512);
  ts = ts * 64; // bit-shift << 6
  ts = ts + shardId;
  return ts * 512 + randid;
}
