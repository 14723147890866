import { EMERGENCY_ALERT } from "../../actions-index"

export function emergencyAlert(state = {}, action = {}) {
    const { type = '', payload = {} } = action

    if (type === EMERGENCY_ALERT) {
        return {
            ...state,
            status: 'success',
            ...payload
        }
    }
    return state
}