import typography from "./typography";
import overrides from "./overrides";
import { standardPalette, darkModePalette } from "./colors";
import { createTheme } from "@material-ui/core/styles";

const theme = (darkMode, elevation) => {
  return createTheme({
    typography,
    overrides: overrides(darkMode, elevation),
    palette: darkMode ? darkModePalette : standardPalette,
  });
};

export default theme;
