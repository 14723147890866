import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DesignList from './components/DesignList';
import DesignEdit from './components/DesignEdit';

const Dashboard = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <DesignList />
      </Route>
      <Route path={`${match.path}/new`} exact={true}>
        <DesignEdit />
      </Route>
      <Route path={`${match.path}/edit/:designid?`} exact={true} render={(props) => <DesignEdit {...props} />} >
      </Route>
    </Switch>
  );
};

export default Dashboard;
