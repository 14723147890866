import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import image from "../../Common/osu-web-header-horiz.png";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    // flexDirection: "row",
    float: "right",
    // justifyContent: "flex-end",
  },
  invoiceNameContainer: {
    // flexDirection: "row",
    float: "left",
    // justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
    width: "50%",
  },
  icon: {
    width: "10px",
    height: "100%",
  },
  reportTitle: {
    color: "#61dafb",
    letterSpacing: 4,
    marginTop: 24,
    fontSize: 25,
    textAlign: "left",
    textTransform: "uppercase",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
  },
  cell: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
  },
  headerText: {
    fontSize: 11,
    fontWeight: 1200,
    color: "#1a245c",
    margin: 8,
  },
});

const InvoiceNo = ({ invoice }) => {
  const ContactInfo = invoice.ContactInfo;
  Object.keys(ContactInfo).forEach((key) => {
    if (ContactInfo[key] === "NULL") {
      ContactInfo[key] = "";
    }
  });
  return (
    <Fragment>
      <Image
        className={`${styles.icon}`}
        alt="OSU horizontal logo"
        src={image}
      />
      <Text style={styles.reportTitle}>Invoice</Text>
      <View style={styles.row}>
        <Text style={[styles.headerText, styles.cell]}>
          {`${ContactInfo.FirstName || ""} ${ContactInfo.LastName || ""}
            ${ContactInfo.Organization || ""}
            ${ContactInfo.Address1 || ""}
            ${ContactInfo.Address2 || ""}
            ${ContactInfo.City || ""}, ${ContactInfo.State || ""} ${
            ContactInfo.Zip || ""
          }  
                `}
        </Text>

        <Text
          style={[styles.headerText, styles.cell]}
        >{`Office of Government Affairs
                15 E 15th Ave
                University Square South
                Columbus, OH 43210-1321
                614-247-8468 Main
                614-292-1279 Direct

                Invoice No: invoice-${invoice.requestid}
                Date: ${invoice.InvoiceDate}
                `}</Text>
      </View>
    </Fragment>
  );
};

export default InvoiceNo;
