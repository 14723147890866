import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Sidebar from "../components/Sidebar";
import {addItem} from "../../../actions-index"
const mapStateToProps = (state) => {
    const { authentication } = state
    const {seasonlistchecked =''} = authentication
    return {
        seasonlistchecked
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItem: () => dispatch(addItem())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));