import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getEmergencyAlert } from '../../../actions-index';
import EmergencyAlert from "../components/";

const mapStateToProps = (state = {} ) => {
    const { emergencyAlert = {} } = state
    const { feed = [] } = emergencyAlert

    return {
        feed
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEmergencyAlert: () => dispatch(getEmergencyAlert())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmergencyAlert));