import React, { Component, Fragment, createRef } from "react";
import Accordion from "@material-ui/core/Accordion";
import API from "@aws-amplify/api";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import { Form, ListHeader } from "semantic-ui-react";
import CenteredGrid from "./components/RequestsGrid";
import { GetRequestsByContact } from "./actions";
import {
  GetContactInfo,
  getAllData,
  GetEventList,
} from "../../util/dropdownconstants";
import {
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemText,
  Hidden,
  Grid,
} from "@material-ui/core";
export default class ViewRequestsByContact extends Component {
  constructor(props) {
    super(props);
    props.generateTitle();
    this.contactId = this.props.contactid;
    this.state = {
      currContactID: null,
      currentContact: {
        id: null,
        title: "",
        description: "",
      },
      reqstatuses: [],
      requestInfo: [
        {
          ContactID: "",
          DateRequestMade: "",
          EventID: "",
          EventName: "",
          Notes: "",
          NumberTicketsPromised: "",
          NumberTicketsRequested: "",
          RequestChargeType: "",
          RequestMethod: "",
          RequestStatus: "",
          RequestType: "",
          TicketCategoryID: "",
          data: "",
          totalprice: 0,
          pk: "",
          sk: "",
        },
      ],
      isLoading: true,
    };
  }
  async componentDidMount() {
    const reqArray = {};
    reqArray.contactId = this.props.contactid;
    this.setState({ currContactID: reqArray.contactId });
    reqArray.status = this.props.status;
    var requestinfo = await GetRequestsByContact(reqArray);
    const uniquestatus = Array.from(
      new Set(requestinfo.map((row) => row.RequestStatus))
    );
    // uniquestatus = ["status-Under Review"];
    this.setState({ reqstatuses: uniquestatus });
    await GetContactInfo(reqArray.contactId).then((response) =>
      this.setState({ currentContact: response.Item })
    );
    for (let item of requestinfo) {
      var paramsTickets = {
        operation: "read",
        payload: {
          IndexName: "gsi_1",
          KeyConditionExpression: "sk=:objecttype and #data=:eventrequestid",
          // FilterExpression: "RequestID = :requestid",
          ExpressionAttributeNames: {
            "#data": "data",
          },
          ExpressionAttributeValues: {
            ":eventrequestid": item.EventID + "#" + item.pk,
            // ":requestid": item.pk,
            ":objecttype": "Ticket",
          },
        },
      };

      var ticketinfo = await getAllData(paramsTickets);
      var eventList = await GetEventList();
      var eventInfo = eventList.filter((event) => {
        return event.pk === item.EventID;
      })[0];
      item.eventInfo = eventInfo || { EventName: "", data: "" };
      if (ticketinfo[0]) {
        var tickets = ticketinfo;
        if (tickets && tickets.length > 0) {
          var sumofpaiditems = { AmountPaid: 0 };
          var sumoflineitems = { Price: 0 };
          if (tickets.length > 1) {
            sumoflineitems = tickets.reduce((a, b) => ({
              Price: parseFloat(a.Price) + parseFloat(b.Price),
            }));
            sumofpaiditems = tickets.reduce((a, b) => ({
              AmountPaid:
                (parseFloat(a.AmountPaid) || 0) +
                (parseFloat(b.AmountPaid) || 0),
            }));
          } else {
            sumofpaiditems.AmountPaid = parseFloat(tickets[0].AmountPaid) || 0;
            sumoflineitems.Price = parseFloat(tickets[0].Price) || 0;
          }
          if (
            item.RequestChargeType &&
            item.RequestChargeType === "No Charge"
          ) {
            item.totalprice = 0;
          } else {
            item.totalprice = sumoflineitems.Price - sumofpaiditems.AmountPaid;
            item.totalpaid = sumofpaiditems.AmountPaid || 0;
          }
        } else {
          item.totalprice = 0;
        }
      } else {
        item.totalprice = 0;
      }
    }
    this.setState({
      requestInfo: requestinfo,
      isLoading: false,
    });
  }
  render() {
    const { isLoading, requestInfo, reqstatuses, currentContact } = this.state;
    var elementsRef = [],
      reqrefstatuses = reqstatuses,
      otherreqstatuses = reqstatuses;
    if (reqrefstatuses.length > 0) {
      reqrefstatuses.map((status, index) => (elementsRef[index] = createRef()));
      // elementsRef = createRef(reqstatuses.map(() => createRef()));
    }
    if (isLoading) {
      return <Typography variant="h1">Loading Requests...</Typography>;
    } else {
      return (
        <div>
          <Typography variant="h1">
            Requests connected to{" "}
            {currentContact.FirstName && currentContact.FirstName !== "NULL"
              ? currentContact.FirstName
              : ""}{" "}
            {currentContact.LastName && currentContact.LastName !== "NULL"
              ? currentContact.LastName
              : ""}{" "}
            {currentContact.IsOrganization
              ? currentContact.Organization
              : currentContact.Organization &&
                currentContact.Organization !== "NULL"
              ? currentContact.Organization
              : ""}
          </Typography>
          <Link to={`/addrequest/${this.state.currEventID}`}>
            Add New Request
          </Link>
          <Link to={`/contacts`}>
            <Form.Button>Go Back to Contacts</Form.Button>
          </Link>
          <div style={{ display: reqstatuses.length > 0 ? null : "none" }}>
            {/* <Typography variant="body2"> */}
            <Fragment>
              <Hidden smDown>
                <Drawer
                  className={"drawer"}
                  variant="permanent"
                  anchor="top"
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <Toolbar />
                  <div className={"drawerContainer"}>
                    {/* <List
                        component="nav"
                        aria-labelledby="nested-list-header"
                      ></List> */}
                    <List component="nav" aria-labelledby="nested-list">
                      <ListHeader>Request statuses on this page: </ListHeader>
                      {otherreqstatuses.map((status, index) => (
                        <ListItem
                          button
                          key={`status${status}`}
                          onClick={() => {
                            let element = elementsRef[index],
                              offset = element.offsetHeight,
                              bodyRect =
                                document.body.getBoundingClientRect().top,
                              elementRect = element.getBoundingClientRect().top,
                              elementPosition = elementRect - bodyRect,
                              offsetPosition = elementPosition - offset * 2.2;

                            window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                            });
                            // elementsRef[index].offsetTop.scrollIntoView()
                          }}
                        >
                          <ListItemText primary={status} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </Fragment>
            {/* </Typography> */}
            {reqstatuses.map(function (status, index) {
              return (
                <div key={status}>
                  <Typography
                    variant="h2"
                    ref={(ref) => (elementsRef[index] = ref)}
                    key={index}
                  >
                    {" "}
                    Request Status {status}
                  </Typography>
                  {JSON.parse(JSON.stringify(requestInfo))
                    .filter(function (y) {
                      return y.RequestStatus === status;
                    })
                    .map((row) => {
                      let requestedforname =
                        row.RequestedFor !== row.ContactID
                          ? (row.RequestedForInfo.FirstName !== "NULL"
                              ? row.RequestedForInfo.FirstName
                              : "") +
                            " " +
                            (row.RequestedForInfo.LastName !== "NULL"
                              ? row.RequestedForInfo.LastName
                              : "") +
                            (row.RequestedForInfo.Organization &&
                            row.RequestedForInfo.Organization !== "NULL"
                              ? " (" + row.RequestedForInfo.Organization + ")"
                              : "")
                          : "";
                      let contactname = row.ContactInfo
                        ? (row.ContactInfo.FirstName &&
                          row.ContactInfo.FirstName !== "NULL"
                            ? row.ContactInfo.FirstName +
                              " " +
                              row.ContactInfo.LastName
                            : "") +
                          (row.ContactInfo.Organization &&
                          row.ContactInfo.Organization !== "NULL"
                            ? " (" + row.ContactInfo.Organization + ")"
                            : "")
                        : "";
                      let headerid = row.pk + "-header";
                      let contentid = row.pk + "-content";
                      return row.RequestStatus === status ? (
                        <div key={row.pk}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={contentid}
                              id={headerid}
                            >
                              <Grid
                                container
                                spacing={3}
                                key={`grid-${row.pk}`}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>Event Name: </b>
                                    {row.eventInfo.EventName}
                                    <br />
                                    {row.eventInfo.data}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    style={{
                                      color:
                                        row.RequestChargeType === "No Charge"
                                          ? "blue"
                                          : row.totalprice
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {row.RequestChargeType === "No Charge"
                                      ? "No Charge"
                                      : row.totalprice || row.totalpaid
                                      ? `Amount Paid: $${
                                          row.totalpaid || 0
                                        }; Amount Remaining: $${row.totalprice}`
                                      : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CenteredGrid
                                row={row}
                                contactnotes={currentContact.ContactNotes}
                                contactname={contactname}
                                requestedfor={requestedforname}
                                currentEvent={row.EventName}
                                eventSeason={row.EventSeason}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <br />
                        </div>
                      ) : null;
                    })}
                </div>
              );
            })}
          </div>
          <div style={{ display: reqstatuses.length < 1 ? null : "none" }}>
            <Typography variant="h2">No Requests found for Contact</Typography>
          </div>
        </div>
      );
    }
  }
}
