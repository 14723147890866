import { GetCurrentDateTime, generateRowId } from "../../util/util";
import API from "@aws-amplify/api";

export async function SaveEmailTemplate(templateName, jsonobj, htmlobj, user) {
  const datetime = await GetCurrentDateTime();
  var newPrimaryHashKey = "emailtemplate-" + generateRowId(4);
  console.log(newPrimaryHashKey);
  console.log(templateName);
  var paramsUpdate = {
    operation: "create",
    payload: {
      Item: {
        pk: newPrimaryHashKey,
        sk: "EmailTemplate",
        data: templateName,
        emailJson: jsonobj,
        emailHtml: htmlobj,
        LastModifiedBy: user,
        LastModifiedDate: datetime,
      },
      ReturnValues: "ALL_OLD",
    },
  };
  API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
    body: paramsUpdate,
  }).then((response) => console.log(response));
}
export async function GetEmailParameters(type, id) {
  var paramsTemplates = {
    operation: "get",
    payload: {
      Key: {
        pk: id,
        sk: type,
      },
    },
  };
  API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
    body: paramsTemplates,
  });
}
export async function GetEmailTemplates(templateID) {
  var paramsTemplates = {
    payload: {},
  };
  if (templateID) {
    paramsTemplates.operation = "read";
    paramsTemplates.payload.KeyConditionExpression =
      "pk = :templateid and sk = :objecttype";
    paramsTemplates.payload.ExpressionAttributeValues = {
      ":objecttype": "EmailTemplate",
      ":templateid": templateID,
    };
  } else {
    paramsTemplates.operation = "read";
    paramsTemplates.payload.KeyConditionExpression = "sk = :objecttype";
    paramsTemplates.payload.ExpressionAttributeValues = {
      ":objecttype": "EmailTemplate",
    };
  }
  const templates = await API.post(
    process.env.REACT_APP_TICKET_TRACKING_API_NAME,
    "/items",
    {
      body: paramsTemplates,
    }
  );
  return templates.Items;
}
