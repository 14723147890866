import { orderBy } from "lodash";
import { getAllData, GetEventList } from "../../util/dropdownconstants";

export async function GetEvents(selectedseasons) {
  const eventList = await GetEventList();
  const pe1 = eventList.filter((item) => {
    return selectedseasons.includes(item.data);
  });
  pe1.forEach(function (item) {
    item.id = item.pk;
    item.Description = `${item.EventName} (${item.EventDate})`;
  });
  return pe1;
}
export async function GetPendingRequests(selectedseasons) {
  const pe1 = await GetEvents(selectedseasons);
  var paramsRequests = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Request",
      },
      ExpressionAttributeNames: { "#data": "data" },
      ProjectionExpression: "pk, EventID, #data, RequestStatus",
    },
  };
  const pr1 = await getAllData(paramsRequests);
  //Link Event Data (name, data, venue, etc) to the Pending Request data and get Count of all pending requests
  var EventIDs = pr1.map((item) => item.EventID);
  var partfilteredEvents = [];
  //get events that have pending requests
  partfilteredEvents = pe1.filter((value) => EventIDs.includes(value.pk));
  var unfilteredRequests = [];
  unfilteredRequests = pr1;
  partfilteredEvents.forEach(function (item) {
    item.Count = unfilteredRequests.filter(
      (obj) => obj.EventID === item.pk && obj.RequestStatus === "Under Review"
    ).length;
    item.TotalCount = unfilteredRequests.filter(
      (obj) => obj.EventID === item.pk
    ).length;
    item.RequestStatus = unfilteredRequests[0].RequestStatus;
  });
  partfilteredEvents = partfilteredEvents.filter((item) => item.Count > 0);
  var arrayofall = [];
  arrayofall.requests = orderBy(partfilteredEvents, ["EventDate"], ["asc"]);
  arrayofall.events = pe1;
  return arrayofall;
}
