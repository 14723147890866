import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./storeConfig";
import AppContainer from "./App/containers/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import axe from "react-axe";
const store = configureStore();
if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_CHECK_ACCESSIBILITY === "true") {
	axe(React, ReactDOM, 1000);
}
function saveToLocalStorage(state) {
	try {
	  const serialisedState = JSON.stringify(state);
	  localStorage.setItem("persistantState", serialisedState);
	} catch (e) {
	  console.warn(e);
	}
  }
store.subscribe(() => saveToLocalStorage(store.getState()));
// store.subscribe(() => {
//     localStorage.setItem('state', JSON.stringify(store.getState()));
// })
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<AppContainer />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
