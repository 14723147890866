import React, { Fragment } from 'react'
import { Typography, Link, Divider, makeStyles } from "@material-ui/core"
import "../styles/index.scss"
import { darkModePalette } from '../../App/styles/colors'

const useStyles = makeStyles(() => ({
    link: {
        color: darkModePalette.primary.main
    },
    divider: {
        backgroundColor: 'white',
        height: '1.25em',
        width: '1px',
        margin: '0 .5em'
    },
}))

const SkipTo = ({ mobile, forceReflow}) => {
    const classes = useStyles()
    
    return <Typography component="div" id="skip">
        <span id="skip-to-descriptor">
            Skip to:
        </span>
        <Link classes={{ root: classes.link }} href="#main" aria-describedby="skip-to-descriptor">main</Link>
        <Divider classes={{ root: classes.divider }} orientation="vertical" />
        <Link classes={{ root: classes.link }} href="#footer" aria-describedby="skip-to-descriptor">footer</Link>
        {mobile && !forceReflow && <Fragment>
            <Divider classes={{ root: classes.divider }} orientation="vertical" />
            <Link classes={{ root: classes.link }} aria-label="top level pages navigation" aria-describedby="skip-to-descriptor" href="#mobile_navigation">bottom navigation</Link>
        </Fragment>}
    </Typography>
}

export default SkipTo;