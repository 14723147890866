import orderBy from "lodash/orderBy";
import { getAllData } from "../../actions-index";
export default async function GetContactList() {
  var paramsContact = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Contact",
      },
      ExpressionAttributeNames: { "#data": "data" },
      ProjectionExpression:
        "#data,pk,EmailAddress,EmailAddress2,FirstName,LastName,Organization,IsOrganization,ContactNotes,ParkingNotes",
    },
  };
  const response = await getAllData(paramsContact);
  const responseData = orderBy(
    response,
    ["IsOrganization", "LastName", "FirstName"],
    ["desc", "asc", "asc"]
  );
  responseData.map(function (tcid) {
    Object.keys(tcid).forEach((key) => {
      if (tcid[key] === "NULL") {
        tcid[key] = "";
      }
    });
    return tcid;
  });
  localStorage.setItem("contactdata", JSON.stringify(responseData));
  return responseData;
}
