import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import "react-datepicker/dist/react-datepicker.css";

class Thanks extends Component {
  constructor(props) {
    super(props);
    props.generateTitle();
  }
  async componentDidMount() {
    //   const query = new URLSearchParams(window.location.search);
    //   const params = {}
    //   // params.token = query.get('token');
    //   params.orderNumber= query.get("orderNumber");
    //   params.totalAmount = query.get("transactionTotalAmount");
    //   params.transactionDate = query.get("transactionDate");
    //   params.eventId = query.get("userChoice1");
    //   params.contactId = query.get("userChoice2");
    //   console.log(params);
    //   var event = {
    //     orderNumber:params.orderNumber,
    //     transactionDate:params.transactionDate,
    //     totalAmount:params.totalAmount,
    //     eventId:params.eventId,
    //     contactId:params.contactId
    //   };
    //   console.log(event);
    //   API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME,"/handlePayment",{
    //     body:event
    //   }).then((response) =>console.log(response));
  }

  render() {
    return (
      <div className="text-center">
        <Typography variant="h1">Thank you for the payment! An email will be sent to you shortly.</Typography>
      </div>
    );
  }
}

export default Thanks;
