import React, { Component } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import { GetContactData } from "../../actions-index";
import TableauReport from "tableau-react";

export default class Reporting extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      query: "",
      bgcolor: "#bdbdbd",
      alphabet: "",
      data: [],
      filteredData: [],
      responseData: {
        data: "",
        pk: "",
        EmailAddress: "",
        EmailAddress2: "",
        FirstName: "",
        LastName: "",
        isOrganization: false,
        Organization: "",
      },
      isLoading: true,
    };
    this.user = sessionStorage.getItem("UserName");
  }
  async componentDidMount() {
    await GetContactData().then((responseData) =>
      this.setState({
        data: responseData,
        isLoading: false,
        responseData: responseData,
      })
    );
  }
  handleInputChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.filterArray();
      }
    );
  };

  render() {
    const MyReport = () => (
      <TableauReport url="https://dataviz.rae.osu.edu/views/OGAFootballTicketTracking/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link" />
    );
    // const { responseData } = this.state;
    if (this.state.isLoading) {
      return (
        <CircularProgress
          className="mt-2"
          width={60}
          height={60}
          color="primary"
        />
      );
    } else {
      return (
        <div className="edit-form">
          <Typography variant="h2">
            <EditIcon />
            Reporting
          </Typography>
          <MyReport />
        </div>
      );
    }
  }
}
