import React, { useState, useEffect, Fragment } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";
import clsx from "clsx";
import { GetPendingRequests } from "../actions";
import {
  Card,
  Avatar,
  Collapse,
  Typography,
  CardContent,
  Badge,
  ListItem,
  ListItemText,
  Tooltip,
  Button,
} from "@material-ui/core";
import EventNote from "@material-ui/icons/EventNote";
import "./index.css";
import { Link } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    width: 112,
    height: 103,
    padding: "2px",
    cursor: "pointer",
    // backgroundColor: red[500]
  },
  expandSelect: {
    border: "1px solid #bb0000",
  },
  avatar: {
    backgroundColor: "#bb0000",
  },
}));
export default function CustomizedTables({ seasons }) {
  const classes = useStyles();
  const [filteredPendingEvents, setFilteredPendingEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [expanded, setExpanded] = React.useState({
    requests: true,
    events: true,
  });
  useEffect(() => {
    async function datalist() {
      if (seasons) {
        if (seasons.length > 0) {
          await GetPendingRequests(seasons).then((response) => {
            setFilteredPendingEvents(response.requests);
            setFilteredEvents(response.events);
          });
        }
      } else {
      }
    }
    datalist();
  }, [seasons]);
  const containerWidth = document.getElementById("main")
    ? document.getElementById("main").clientWidth
    : "no";
  const containerwidth = containerWidth / 4;
  const handleExpandClick = (value) => () => {
    setExpanded((prevState) => {
      return { ...prevState, [value]: !prevState[value] };
    });
  };
  const PEventsColumns = [
    {
      field: "EventDate",
      headerName: "Event Date",
      width: containerwidth,
    },
    {
      field: "EventName",
      headerName: "Event Name",
      width: containerwidth,
    },
    {
      field: "Count",
      headerName: "Pending/Total Requests",
      renderCell: (params) => (
        <strong>
          <Typography variant="body2">
            {params.value}/{params.row.TotalCount}
          </Typography>
        </strong>
      ),
      width: containerwidth,
    },
    { field: "Venue", headerName: "Venue", width: containerwidth / 2 },
    {
      field: "pk",
      headerName: " ",
      renderCell: (params) => (
        <strong>
          <Typography variant="body2">
            <Tooltip
              key="requestsbutton"
              aria-label="requestsbutton"
              title="View Requests"
            >
              <Button
                component={Link}
                to={`/requests/${params.getValue(params.id, "pk")}`}
              >
                <EventNote />
              </Button>
            </Tooltip>
          </Typography>
        </strong>
      ),
    },
  ];
  const AllEventsColumns = [
    {
      field: "EventDate",
      headerName: "Event Date",
      width: containerwidth,
    },
    {
      field: "EventName",
      headerName: "Event Name",
      width: containerwidth,
    },
    { field: "Venue", headerName: "Venue", width: containerwidth },
    {
      field: "pk",
      headerName: " ",
      renderCell: (params) => (
        <strong>
          <Typography variant="body2">
            <Link to={`/event/${params.value}`}>Edit Event</Link>
            <br />
            <Link to={`/requests/${params.value}`}>View Requests</Link>
            <br />
            <Link to={`/addrequest/${params.value}`}>Add Request</Link>
            <br />
            <Link
              to={`/addtickets/${params.getValue(params.id, "data")}/${
                params.value
              }`}
            >
              Add Tickets
            </Link>
            {/* <Link to={`/tickets/${this.state.selectedSeason}/${pk}`}>
							Manage Tickets
						</Link> */}
            <br />
          </Typography>
        </strong>
      ),
    },
  ];

  return (
    <Fragment>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={<Avatar>{seasons ? seasons.length : 0}</Avatar>}
      >
        <Card
          className={clsx(classes.root, {
            [classes.expandSelect]: expanded["seasons"],
          })}
          onClick={handleExpandClick("seasons")}
        >
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p">
              Selected Seasons
            </Typography>
          </CardContent>
        </Card>
      </Badge>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={<Avatar>{filteredEvents.length}</Avatar>}
      >
        <Card
          className={clsx(classes.root, {
            [classes.expandSelect]: expanded["events"],
          })}
          onClick={handleExpandClick("events")}
        >
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p">
              Total Events
            </Typography>
          </CardContent>
        </Card>
      </Badge>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <Avatar className={classes.avatar}>
            {filteredPendingEvents.length}
          </Avatar>
        }
      >
        <Card
          className={clsx(classes.root, {
            [classes.expandSelect]: expanded["requests"],
          })}
          onClick={handleExpandClick("requests")}
        >
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p">
              Events with Pending Requests
            </Typography>
          </CardContent>
        </Card>
      </Badge>
      <Collapse in={expanded["seasons"]} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6">Selected Seasons</Typography>
          {seasons
            ? seasons.length > 0
              ? seasons.map((cat) => (
                  <Fragment key={`${cat}`}>
                    {/* <Typography> */}
                    <ListItem button>
                      <ListItemText primary={cat} />
                    </ListItem>
                  </Fragment>
                ))
              : null
            : null}
        </CardContent>
      </Collapse>
      <Collapse in={expanded["events"]} timeout="auto" unmountOnExit>
        <CardContent>
          <div className="scarlettable" hidden={filteredEvents.length < 1}>
            <Typography variant="h6">All Events in Selected Seasons</Typography>
            <DataGrid
              overflowX="scroll"
              id="datagrid"
              autoHeight={true}
              rowHeight={75}
              rows={filteredEvents}
              rowsPerPageOptions={[5]}
              columns={AllEventsColumns}
              pageSize={5}
              disableSelectionOnClick={true}
            />
          </div>
        </CardContent>
      </Collapse>
      <Collapse in={expanded["requests"]} timeout="auto" unmountOnExit>
        <CardContent>
          <div
            className="scarlettable"
            hidden={filteredPendingEvents.length < 1}
          >
            <Typography variant="h6">Events with Pending Requests</Typography>
            <DataGrid
              overflowX="scroll"
              components={{}}
              id="datagrid"
              rows={filteredPendingEvents}
              columns={PEventsColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick={true}
              autoHeight={true}
            />
          </div>
        </CardContent>
      </Collapse>
    </Fragment>
  );
}
