import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListTickets from "./ListTickets";
import { GetEventBySeason, GetSeasonList } from "../../../actions-index";
import sortBy from "lodash/sortBy";

class SeasonSelect extends Component {
  constructor(props) {
    super(props);
    props.generateTitle();
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.state = {
      isLoading: true,
      is2Loading: true,
      category: "",
      selectedSeason: "",
      selectedEvent: "",
      eventSeason: "",
      dataSource: {},
      eventsBySeason: [],
      uniqueCats: [],
      seasonYears: [],
      allSeasons: [],
      refreshEventList: true,
    };
  }
  async componentDidMount() {
    const seasonData = await GetSeasonList();
    this.setState({
      seasonYears: sortBy(seasonData.uniqueYears, ["pk"]),
    });
    if (this.props.location.seasonid) {
      const selectedseason = this.state.seasonYears.find(
        (item) => item.pk === this.props.location.seasonid
      );
      this.setState({
        selectedSeason: selectedseason.pk,
      });
      const seasonEvents = await GetEventBySeason(this.props.location.seasonid);
      const selectedevent = seasonEvents.find(
        (item) => item.pk === this.props.location.eventid
      );
      this.setState({
        seasonEvents,
        selectedEvent: selectedevent.pk,
        EventInfo: selectedevent,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  handleSeasonChange = async (event) => {
    const seasonEvents = await GetEventBySeason(event.target.value);
    const selectedseason = this.state.seasonYears.find(
      (item) => item.pk === event.target.value
    );
    const selectedCat = event.target.value;
    this.setState({ category: selectedCat });
    this.setState({
      eventSeason: `${selectedCat}-${this.state.selectedYear}`,
      seasonEvents,
      selectedSeason: selectedseason.pk,
    });
  };
  handleEventChange = (event) => {
    const selectedEvent = this.state.seasonEvents.find(
      (item) => item.pk === event.target.value
    );
    this.setState({
      selectedEvent: selectedEvent.pk,
      EventInfo: selectedEvent,
    });
  };
  render() {
    const {
      seasonEvents,
      selectedSeason,
      selectedEvent,
      seasonYears,
      isLoading,
    } = this.state;
    const formControl = {
      margin: 1,
      minWidth: 200,
      fontSize: 16,
    };
    if (isLoading) {
      return <div></div>;
    } else {
      return (
        <div>
          <FormControl style={formControl}>
            <InputLabel id="simple-select-label">
              <strong>
                <big>Select Season</big>
              </strong>
            </InputLabel>
            <Select
              labelid="simple-select-label"
              id="simple-select"
              value={selectedSeason}
              onChange={this.handleSeasonChange}
            >
              {/* <MenuItem value="" /> */}
              {seasonYears.map((rows) => (
                <MenuItem key={rows.pk} value={rows.pk}>
                  {rows.pk}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          {this.state.selectedSeason ? (
            <FormControl style={formControl}>
              <InputLabel id="simple-select-label">
                <strong>
                  <big>Select Event</big>
                </strong>
              </InputLabel>
              <br />
              <Select
                labelid="simple-select-label"
                id="simple-select"
                value={selectedEvent}
                onChange={this.handleEventChange}
              >
                {seasonEvents.map((rows) => (
                  <MenuItem key={rows.pk} value={rows.pk}>
                    {rows.EventName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div></div>
          )}
          {selectedEvent ? (
            <ListTickets
              data={selectedEvent}
              EventInfo={this.state.EventInfo}
            />
          ) : null}
          {/* {selectedYear.length > 0 ? (
				) : (
					<div></div>
				)} */}

          <br />
        </div>
      );
    }
  }
}

export default SeasonSelect;
