import React, { useMemo } from "react";
import { Page, Document, StyleSheet } from "@react-pdf/renderer";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const Invoice = ({ invoice }) => {
  const invoicetitle =
    "Invoice " +
    invoice.InvoiceDate +
    " - " +
    (invoice.ContactInfo.FirstName
      ? invoice.ContactInfo.FirstName + " " + invoice.ContactInfo.LastName
      : invoice.ContactInfo.Organization);
  return useMemo(
    () => (
      <Document title={invoicetitle}>
        <Page size="A4" style={styles.page}>
          {/* <Image style={styles.logo} src={osuemail} /> */}
          {/* <InvoiceTitle title="Invoice" /> */}
          <InvoiceNo invoice={invoice} />
          {/* <BillTo invoice={invoice} /> */}
          <InvoiceItemsTable invoice={invoice} />
          <InvoiceThankYouMsg />
        </Page>
      </Document>
    ),
    [invoice, invoicetitle]
  );
};

export default Invoice;
