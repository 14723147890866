import React, { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Form, Select } from "semantic-ui-react";
import {
  GetSeasonList,
  GetEventBySeason,
  GetContactData,
} from "../../../actions-index";
import { GetPaymentsByContact, GetPaymentsBySeason } from "../actions";
import sortBy from "lodash/sortBy";
import "./index.css";

class Payments extends React.Component {
  constructor(props) {
    super(props);

    props.generateTitle();
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleSearchBy = this.handleSearchBy.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.state = {
      query: "",
      data: [],
      filteredData: [],
      contactLineItemInfo: [],
      eventList: [],
      eventSelectList: [],
      isLoading: true,
      isLoading2: true,
      responseData: [],
      requestData: [],
      seasonCategories: [],
      searchOn: "",
      addselected: false,
      editingselected: false,
      lineitemlist: [],
      currentpmtid: "",
      searchdata: {},
      paymentslist: [],
      uniqueCats: [],
      category: "",
      seasonYears: [],
      selectedYear: "",
      currentSearch: {
        ContactID: "",
        ContactInfo: "",
        EventID: "",
        EventSeason: "",
      },
    };
  }
  filterArray = () => {
    let searchString = this.state.query;
    let responseData = this.state.data;
    if (searchString.length > 0) {
      responseData = responseData.filter((element) => {
        return element.data.toLowerCase().includes(searchString.toLowerCase());
      });
      this.setState({
        responseData,
      });
    }
  };
  handleInputChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.filterArray();
      }
    );
  };
  handleEditClick = () => {
    this.setState({ currentpmtid: "" });
  };
  handleSearchBy = async (event, newSearchBy) => {
    if (newSearchBy !== localStorage.getItem("currentSearchBy")) {
      this.setState({
        currentSearch: {
          ContactID: "",
          ContactInfo: "",
          EventID: "",
          EventSeason: "",
        },
      });
      localStorage.setItem("currentSearchBy", newSearchBy);
    }
    localStorage.setItem("currentSearchBy", newSearchBy);
    if (newSearchBy === "event") {
      this.setState({
        seasonCategories: JSON.parse(localStorage.getItem("uniqueSeasons")),
        isLoading: false,
      });
    }
  };
  handleSeasonChange = async (e, { id, value }) => {
    var eventinfo = [],
      newpaymentslist = [],
      paymentslist = [];
    if (localStorage.getItem("currentSearchBy") === "contact") {
      eventinfo = this.state.contactEventList.filter(function (event) {
        return event.EventSeason.toLowerCase().includes(value.toLowerCase());
      });
      paymentslist = this.state.contactLineItemInfo
        .filter(function (lineitem) {
          return lineitem.EventSeason === value;
        })
        .map((pmt) => pmt.PaymentID);
      newpaymentslist = this.state.contactPaymentslist.filter((payment) => {
        return paymentslist.includes(payment.pk);
      });
      for (let pmt of newpaymentslist) {
        pmt.id = pmt.pk;
        if (
          Object.prototype.toString.call(pmt.DateReceived) !==
            "[object Date]" &&
          pmt.DateReceived.indexOf("/") < 0
        ) {
          let year = pmt.DateReceived.substring(0, 4);
          let month = pmt.DateReceived.substring(4, 6);
          let day = pmt.DateReceived.substring(6, 8);
          let testdate = new Date(`${year}-${month}-${day}`);
          month = ("0" + (testdate.getMonth() + 1)).slice(-2);
          testdate = new Date(`${year}-${month}-${day}`);
          pmt.stringdate = `${year}-${month}-${day}`;
        } else {
          pmt.stringdate = pmt.DateReceived;
        }
      }
      // newpaymentslist = newpaymentslist.forEach((pmt) => {
      //   pmt.id = pmt.pk;
      // });
    } else {
      var LineItemInfo = [];
      eventinfo = JSON.parse(localStorage.getItem("eventlist")).filter(
        (event) => event.data === value
      );
      newpaymentslist = await GetPaymentsBySeason(null, value);
      for (let payment of newpaymentslist) {
        LineItemInfo = payment.LineItemInfo
          ? LineItemInfo.concat(...payment.LineItemInfo)
          : [];
      }
      for (let pmt of newpaymentslist) {
        pmt.PayerName =
          (pmt.PayerInfo.FirstName && pmt.PayerInfo.FirstName !== "NULL"
            ? pmt.PayerInfo.FirstName
            : "") +
          " " +
          (pmt.PayerInfo.LastName && pmt.PayerInfo.LastName !== "NULL"
            ? pmt.PayerInfo.LastName
            : "") +
          (pmt.PayerInfo.Organization && pmt.PayerInfo.Organization !== "NULL"
            ? " (" + pmt.PayerInfo.Organization + ")"
            : "");
        pmt.id = pmt.pk;
        if (
          Object.prototype.toString.call(pmt.DateReceived) !==
            "[object Date]" &&
          pmt.DateReceived.indexOf("/") < 0
        ) {
          let year = pmt.DateReceived.substring(0, 4);
          let month = pmt.DateReceived.substring(4, 6);
          let day = pmt.DateReceived.substring(6, 8);
          let testdate = new Date(`${year}-${month}-${day}`);
          month = ("0" + (testdate.getMonth() + 1)).slice(-2);
          testdate = new Date(`${year}-${month}-${day}`);
          pmt.stringdate = `${year}-${month}-${day}`;
        } else {
          pmt.stringdate = pmt.DateReceived;
        }
      }
      this.setState({
        contactPaymentslist: newpaymentslist,
        contactLineItemInfo: LineItemInfo,
      });
    }
    this.setState(
      {
        eventList: eventinfo,
        currentSearch: Object.assign({}, this.state.currentSearch, {
          [id]: value,
        }),
        paymentslist: newpaymentslist,
      },
      () => {}
    );
  };
  handleCatChange = (event) => {
    const selectedCat = event.target.value;
    this.setState({ category: selectedCat });
    this.setState({
      eventSeason: `${selectedCat}-${this.state.selectedYear}`,
    });
  };
  handleEventChange = async (e, { id, value }) => {
    if (this.state.paymentslist) {
      var paymentslist = this.state.contactLineItemInfo
        .filter(function (lineitem) {
          return lineitem.EventID === value;
        })
        .map((pmt) => pmt.PaymentID);
      var newpaymentslist = this.state.contactPaymentslist.filter((payment) => {
        return paymentslist.includes(payment.pk);
      });
    }
    this.setState(
      {
        currentSearch: Object.assign({}, this.state.currentSearch, {
          [id]: value,
        }),
        paymentslist: newpaymentslist,
      },
      () => {}
    );
  };
  handleContactChange = async (e, { value }) => {
    var LineItemInfo = [],
      seasonCategories = [],
      eventList = [];
    const paymentsbycontact = await GetPaymentsByContact(value);
    if (paymentsbycontact) {
      if (paymentsbycontact[0] && paymentsbycontact[0].LineItemInfo) {
        paymentsbycontact.forEach(
          (payment) =>
            (LineItemInfo = LineItemInfo.concat(...payment.LineItemInfo))
        );
        var contactin = this.state.responseData
          .filter((element) => {
            return element.pk === value;
          })
          .map((contact) => contact.data)[0];
        seasonCategories = sortBy(
          Array.from(
            new Set(LineItemInfo.map((row) => row.EventSeason || "none"))
          )
        ).map(function (season) {
          return { value: season, text: season, key: season };
        });
        eventList = Array.from(new Set(LineItemInfo.map((row) => row.EventID)));
        eventList.forEach(function (element, index) {
          var ind = LineItemInfo.map((row) => row.EventID).indexOf(element);
          return (eventList[index] = LineItemInfo[ind]);
        });
        paymentsbycontact.forEach((pmt) => {
          pmt.PayerName =
            (pmt.PayerInfo.FirstName && pmt.PayerInfo.FirstName !== "NULL"
              ? pmt.PayerInfo.FirstName
              : "") +
            " " +
            (pmt.PayerInfo.LastName && pmt.PayerInfo.LastName !== "NULL"
              ? pmt.PayerInfo.LastName
              : "") +
            (pmt.PayerInfo.IsOrganization &&
            pmt.PayerInfo.Organization !== "NULL"
              ? " " + pmt.PayerInfo.Organization
              : pmt.PayerInfo.Organization &&
                pmt.PayerInfo.Organization !== "NULL"
              ? " (" + pmt.PayerInfo.Organization + ")"
              : "");
          pmt.id = pmt.pk;
          if (
            Object.prototype.toString.call(pmt.DateReceived) !==
              "[object Date]" &&
            pmt.DateReceived.indexOf("/") < 0
          ) {
            let year = pmt.DateReceived.substring(0, 4);
            let month = pmt.DateReceived.substring(4, 6);
            let day = pmt.DateReceived.substring(6, 8);
            let testdate = new Date(`${year}-${month}-${day}`);
            month = ("0" + (testdate.getMonth() + 1)).slice(-2);
            testdate = new Date(`${year}-${month}-${day}`);
            pmt.stringdate = `${year}-${month}-${day}`;
          } else {
            pmt.stringdate = pmt.DateReceived;
          }
        });
        this.setState({
          seasonCategories: seasonCategories,
          contactPaymentslist: paymentsbycontact,
          contactLineItemInfo: LineItemInfo,
          paymentslist: paymentsbycontact,
          contactEventList: eventList,
          currentSearch: Object.assign({}, this.state.currentSearch, {
            ContactInfo: contactin,
          }),
        });
      } else {
        this.setState({
          seasonCategories: [],
          contactPaymentslist: [],
          contactLineItemInfo: [],
          paymentslist: [],
          contactEventList: [],
          currentSearch: Object.assign({}, this.state.currentSearch, {
            ContactInfo: "",
          }),
        });
      }
    }
    this.setState(
      {
        currentSearch: Object.assign({}, this.state.currentSearch, {
          ContactID: value,
          EventSeason: "",
          EventID: "",
        }),
      },
      () => {}
    );
  };
  async componentDidMount() {
    await GetContactData().then((response) => {
      this.setState({
        responseData: response,
        data: response,
        searchString: response,
        isLoading: false,
      });
    });
    if (localStorage.getItem("currentSearchBy") === null) {
      localStorage.setItem("currentSearchBy", "event");
    }
    if (localStorage.getItem("currentSearchBy") === "event") {
      this.setState({
        seasonCategories: JSON.parse(localStorage.getItem("uniqueSeasons")),
        isLoading: false,
      });
    }
    const containerWidth = document.getElementById("EventSeason")
      ? document.getElementById("EventSeason").clientWidth
      : document.getElementById("ContactID")
      ? document.getElementById("ContactID").clientWidth
      : "no";
    this.setState({ containerwidth: (containerWidth - 100) / 4 });
    if (this.props.contactid) {
      localStorage.setItem("currentSearchBy", "contact");
      this.setState({
        currentSearch: {
          ContactID: this.props.contactid,
        },
      });
      this.handleContactChange("", { value: this.props.contactid });
    }
  }
  render() {
    var {
      isLoading,
      addselected,
      currentSearch,
      paymentslist,
      searchdata,
      eventList,
      seasonCategories,
      currentpmtid,
      containerwidth,
    } = this.state;

    var searchBy = localStorage.getItem("currentSearchBy");
    var eventSelectList = [];
    const EditPaymentComponent = React.lazy(() => import("./EditPayment"));
    const AddPaymentComponent = React.lazy(() => import("./AddPayment"));
    const PaymentColumns = [
      {
        field: "PayerName",
        headerName: "Paid By",
        width: containerwidth,
      },
      { field: "AmountPaid", headerName: "Amount", width: containerwidth },
      {
        field: "PaymentDetails",
        headerName: "Description",
        width: containerwidth,
      },
      {
        field: "stringdate",
        headerName: "Date Received",
        width: containerwidth,
      },
      { field: "button", headerName: " " },
    ];
    if (isLoading) {
      return (
        <Typography
          className="d-flex flex-column align-items-center"
          component="span"
          variant="h5"
        >
          Loading...
          <CircularProgress
            className="mt-2"
            width={60}
            height={60}
            color="primary"
          />
        </Typography>
      );
    } else {
      var contactList = this.state.responseData.map(function (tcid) {
        return {
          value: tcid.pk,
          text: tcid.FirstName + " " + tcid.LastName + " " + tcid.Organization,
          key: tcid.pk,
        };
      });
      if (eventList.length > 0) {
        eventSelectList = eventList.map(function (event) {
          return {
            value: event.EventID ? event.EventID : event.pk,
            text: event.EventName,
            key: event.EventID ? event.EventID : event.pk,
          };
        });
      }
      return (
        <Fragment>
          <Typography>Filter Type:</Typography>
          <ToggleButtonGroup
            value={searchBy}
            exclusive
            onChange={this.handleSearchBy}
            aria-label="search by"
            label="Filter Type"
          >
            <ToggleButton value="contact" aria-label="left">
              Contact
            </ToggleButton>
            <ToggleButton value="event" aria-label="left">
              Season/Event
            </ToggleButton>
          </ToggleButtonGroup>
          <div style={{ zIndex: "5" }}>
            {searchBy === "contact" ? (
              <Form>
                <Form.Field
                  control={Select}
                  fluid
                  label={{
                    children: "Contact Name",
                    htmlFor: "form-select-control-contactid",
                  }}
                  search
                  searchInput={{ id: "form-select-control-contactid" }}
                  onAddItem={this.handleInputChange}
                  name="Contact Name"
                  className="form-control"
                  value={currentSearch.ContactID}
                  options={contactList}
                  placeholder="Search for Contact Name or Email..."
                  onChange={this.handleContactChange}
                  id="ContactID"
                />
              </Form>
            ) : null}
            {currentSearch.ContactID.length > 0 || searchBy === "event" ? (
              seasonCategories.length > 0 ? (
                <Form.Select
                  fluid
                  search
                  label="Season Category and Year"
                  name="Event Season"
                  className="form-control"
                  value={currentSearch.EventSeason}
                  options={seasonCategories}
                  placeholder="Event Season"
                  onChange={this.handleSeasonChange}
                  id="EventSeason"
                />
              ) : (
                <Typography>No Payments found for customer</Typography>
              )
            ) : null}
            {eventSelectList.length > 0 && currentSearch.EventSeason ? (
              <Form.Select
                fluid
                label="Event Name"
                name="Event"
                className="form-control"
                value={currentSearch.EventID}
                options={eventSelectList}
                placeholder="Event"
                onChange={this.handleEventChange}
                id="EventID"
              />
            ) : null}

            <ToggleButton
              value="check"
              selected={addselected}
              onChange={() => {
                this.setState({
                  addselected: !addselected,
                  editing: addselected,
                  searchdata: !addselected ? currentSearch : null,
                });
              }}
            >
              {addselected ? "Cancel New Payment" : "Add a New Payment"}
            </ToggleButton>
          </div>
          <Container style={{ border: "1px solid #bb0000" }}>
            {currentpmtid ? (
              <EditPaymentComponent
                currentpmtid={this.state.currentpmtid}
                onCancel={this.handleEditClick}
                ref={(ref) => (this.editRef = ref)}
              />
            ) : null}
            {addselected ? <AddPaymentComponent search={searchdata} /> : null}
          </Container>
          {paymentslist.length > 0 ? (
            <div className="scarlettable">
              <DataGrid
                overflowX="scroll"
                id="datagrid"
                rows={paymentslist}
                columns={PaymentColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={true}
                autoHeight={true}
                disableExtendRowFullWidth={false}
                onRowClick={(props) => {
                  this.setState({
                    addselected:
                      addselected && !currentpmtid ? !addselected : addselected,
                    currentpmtid: currentpmtid !== props.id ? props.id : "",
                    searchdata: !currentpmtid ? null : searchdata,
                  });
                  this.editRef
                    ? window.scrollTo(0, this.editRef.offsetTop - 64)
                    : window.scrollTo(0, this.offsetTop);
                }}
              />
            </div>
          ) : null}
        </Fragment>
      );
    }
  }
}
export default Payments;
