import React, { Component, Fragment, createRef } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import { ListHeader } from "semantic-ui-react";
import CenteredGrid from "./components/RequestsGrid";
import {
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemText,
  Hidden,
  Grid,
} from "@material-ui/core";
import {
  GetRequestsByEvent,
  GetEventByEventID,
  GetTicketsByRequests,
} from "./actions";
import { GetContactData } from "../../actions-index";
export default class ViewRequestsByEvent extends Component {
  constructor(props) {
    super(props);
    props.generateTitle();
    this.eventId = this.props.eventid;
    this.state = {
      currEventID: null,
      currentEvent: {
        id: null,
        title: "",
        description: "",
        EventName: "",
        data: "",
      },
      reqstatuses: [],
      requestInfo: [
        {
          ContactID: "",
          DateRequestMade: "",
          EventID: "",
          Notes: "",
          NumberTicketsPromised: "",
          NumberTicketsRequested: "",
          RequestChargeType: "",
          RequestMethod: "",
          RequestStatus: "",
          RequestType: "",
          TicketCategoryID: "",
          data: "",
          totalprice: 0,
          pk: "",
          sk: "",
        },
      ],
      isLoading: true,
    };
  }
  async componentDidMount() {
    const reqArray = {};
    reqArray.eventId = this.props.eventid;
    this.setState({ currEventID: reqArray.eventId });
    reqArray.status = this.props.status;
    var requestinfo = await GetRequestsByEvent(reqArray);
    const uniquestatus = Array.from(
      new Set(requestinfo.map((row) => row.RequestStatus))
    );
    this.setState({ reqstatuses: uniquestatus });
    var eventInfo = await GetEventByEventID(reqArray.eventId);
    const ticketInfo = await GetTicketsByRequests(
      this.props.eventid,
      requestinfo
    );
    var ContactInfo = await GetContactData();
    for (let item of requestinfo) {
      let newContactInfo = ContactInfo.filter((contact) => {
        return contact.pk === item.ContactID;
      });
      item.ContactInfo = newContactInfo[0];
      item.eventInfo = eventInfo;
      if (ticketInfo[0]) {
        var tickets = ticketInfo.filter((ticket) => {
          return ticket.RequestID === item.pk;
        });
        if (tickets.length > 0) {
          item.tickets = tickets;
          var sumofpaiditems = { AmountPaid: 0 };
          var sumoflineitems = { Price: 0 };
          if (tickets.length > 1) {
            sumoflineitems = tickets.reduce((a, b) => ({
              Price: parseFloat(a.Price) + parseFloat(b.Price),
            }));
            sumofpaiditems = tickets.reduce((a, b) => ({
              AmountPaid:
                (parseFloat(a.AmountPaid) || 0) +
                (parseFloat(b.AmountPaid) || 0),
            }));
          } else {
            sumofpaiditems.AmountPaid = parseFloat(tickets[0].AmountPaid) || 0;
            sumoflineitems.Price = parseFloat(tickets[0].Price) || 0;
          }
          if (
            item.RequestChargeType &&
            item.RequestChargeType === "No Charge"
          ) {
            item.totalprice = 0;
          } else {
            item.totalprice = sumoflineitems.Price - sumofpaiditems.AmountPaid;
            item.totalpaid = sumofpaiditems.AmountPaid || 0;
          }
        } else item.totalprice = 0;
      } else {
        item.totalprice = 0;
      }
    }
    this.setState({
      requestInfo: requestinfo,
      currentEvent: eventInfo,
      isLoading: false,
    });
  }
  render() {
    const { isLoading, requestInfo, reqstatuses, currentEvent } = this.state;
    var elementsRef = [],
      reqrefstatuses = reqstatuses,
      otherreqstatuses = reqstatuses;
    if (reqrefstatuses.length > 0) {
      reqrefstatuses.map((status, index) => (elementsRef[index] = createRef()));
      // elementsRef = createRef(reqstatuses.map(() => createRef()));
    }
    if (isLoading) {
      return <Typography variant="h1">Loading Requests...</Typography>;
    } else {
      return (
        <div>
          <Typography variant="h1">
            {currentEvent.EventName} ({currentEvent.data})
          </Typography>
          <Link to={`/addrequest/${this.state.currEventID}`}>
            Add New Request
          </Link>
          {/* {reqstatuses.length > 0 ? ( */}
          <div style={{ display: reqstatuses.length > 0 ? null : "none" }}>
            <Fragment>
              <Hidden smDown>
                <Drawer
                  className={"drawer"}
                  variant="permanent"
                  anchor="top"
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <Toolbar />
                  <div className={"drawerContainer"}>
                    {/* <List
                        component="nav"
                        aria-labelledby="nested-list-header"
                      ></List> */}
                    <List component="nav" aria-labelledby="nested-list">
                      <ListHeader>Request statuses on this page: </ListHeader>
                      {otherreqstatuses.map((status, index) => (
                        <ListItem
                          button
                          key={`status${status}`}
                          onClick={() => {
                            let element = elementsRef[index],
                              offset = element.offsetHeight,
                              bodyRect =
                                document.body.getBoundingClientRect().top,
                              elementRect = element.getBoundingClientRect().top,
                              elementPosition = elementRect - bodyRect,
                              offsetPosition = elementPosition - offset * 2.2;

                            window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                            });
                            // elementsRef[index].offsetTop.scrollIntoView()
                          }}
                        >
                          <ListItemText primary={status} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </Fragment>
            {reqstatuses.map(function (status, index) {
              return (
                <div key={status}>
                  <Typography
                    variant="h2"
                    ref={(ref) => (elementsRef[index] = ref)}
                    key={index}
                  >
                    {" "}
                    Request Status {status}
                  </Typography>
                  {JSON.parse(JSON.stringify(requestInfo))
                    .filter(function (y) {
                      return y.RequestStatus === status;
                    })
                    .map((row) => {
                      let requestedforname =
                        row.RequestedFor !== row.ContactID
                          ? (row.RequestedForInfo.FirstName !== "NULL"
                              ? row.RequestedForInfo.FirstName
                              : "") +
                            " " +
                            (row.RequestedForInfo.LastName !== "NULL"
                              ? row.RequestedForInfo.LastName
                              : "") +
                            (row.RequestedForInfo.Organization &&
                            row.RequestedForInfo.Organization !== "NULL"
                              ? " (" + row.RequestedForInfo.Organization + ")"
                              : "")
                          : "";
                      let contactname = row.ContactInfo
                        ? (row.ContactInfo.FirstName &&
                          row.ContactInfo.FirstName !== "NULL"
                            ? row.ContactInfo.FirstName
                            : "") +
                          " " +
                          (row.ContactInfo.LastName &&
                          row.ContactInfo.LastName !== "NULL"
                            ? row.ContactInfo.LastName
                            : "") +
                          (row.ContactInfo.Organization &&
                          row.ContactInfo.Organization !== "NULL"
                            ? " (" + row.ContactInfo.Organization + ")"
                            : "")
                        : "";
                      let headerid = row.pk + "-header";
                      let contentid = row.pk + "-content";
                      return row.RequestStatus === status ? (
                        <div key={row.pk}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={contentid}
                              id={headerid}
                            >
                              <Grid
                                container
                                spacing={3}
                                key={`grid-${row.pk}`}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>Customer: </b>
                                    {contactname}
                                    <br />
                                    {requestedforname
                                      ? "Requested For:" + requestedforname
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    style={{
                                      color:
                                        row.RequestChargeType === "No Charge"
                                          ? "blue"
                                          : row.totalprice
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {row.RequestChargeType === "No Charge"
                                      ? "No Charge"
                                      : row.totalprice || row.totalpaid
                                      ? `Amount Paid: $${
                                          row.totalpaid || 0
                                        }; Amount Remaining: $${row.totalprice}`
                                      : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CenteredGrid
                                row={row}
                                contactname={contactname}
                                requestedfor={requestedforname}
                                contactnotes={row.ContactInfo.ContactNotes}
                                currentEvent={currentEvent.EventName}
                                eventSeason={currentEvent.data}
                                // lastSentRequestDate = {"test"}
                                // requestInfo = {requestInfo}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <br />
                        </div>
                      ) : null;
                    })}
                </div>
              );
            })}
          </div>
          <div style={{ display: reqstatuses.length < 1 ? null : "none" }}>
            <Typography variant="body1">
              No requests found for this event.
            </Typography>
          </div>
        </div>
      );
    }
  }
}
