import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { Form } from "semantic-ui-react";
import { SaveEmailTemplate, GetEmailTemplates } from "../actions";
const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
`;

const Bar = styled.div`
	flex: 1;
	background-color: #61dafb;
	color: #000;
	padding: 10px;
	display: flex;
	max-height: 40px;

	h1 {
		flex: 1;
		font-size: 16px;
		text-align: left;
	}

	button {
		flex: 1;
		padding: 10px;
		margin-left: 10px;
		font-size: 14px;
		font-weight: bold;
		background-color: #000;
		color: #fff;
		border: 0px;
		max-width: 150px;
		cursor: pointer;
	}

	a {
		flex: 1;
		padding: 10px;
		margin-left: 10px;
		font-size: 14px;
		font-weight: bold;
		color: #fff;
		border: 0px;
		cursor: pointer;
		text-align: right;
		text-decoration: none;
		line-height: 160%;
	}
`;

const DesignEdit = (props) => {
	const [template, setTemplate] = useState(null);
	useEffect(() => {
		async function getTemplate() {
			const temp = await GetEmailTemplates(props.match.params.designid);
			setTemplate(temp);
		}
		getTemplate();
	}, [props.match.params.designid]);
	var headerblock = [];
	headerblock = [
		{
			category: "Headers",
			tags: ["osuheader", "osulogo", "logo", "header"],
			data: {
				cells: [1],
				columns: [
					{
						contents: [
							{
								type: "image",
								values: {
									containerPadding: "10px",
									_meta: {
										htmlClassNames: "u_content_image",
									},
									selectable: true,
									draggable: true,
									duplicatable: true,
									deletable: true,
									src: {
										url:
											"https://s3.amazonaws.com/unroll-images-production/projects%2F6611%2F1602886261065-osuemail.png",
										width: 448,
										height: 64,
									},
									textAlign: "center",
									altText: "Image",
									action: {
										name: "web",
										values: {
											href: "",
											target: "_blank",
										},
									},
									hideDesktop: false,
									hideMobile: false,
								},
							},
						],
						values: {
							_meta: {
								htmlClassNames: "u_column",
							},
							border: {
								borderTopWidth: "0px",
								borderTopStyle: "solid",
								borderTopColor: "#CCC",
								borderLeftWidth: "0px",
								borderLeftStyle: "solid",
								borderLeftColor: "#CCC",
								borderRightWidth: "0px",
								borderRightStyle: "solid",
								borderRightColor: "#CCC",
								borderBottomWidth: "3px",
								borderBottomStyle: "solid",
								borderBottomColor: "#bb0000",
							},
						},
					},
				],
				values: {
					displayCondition: null,
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: false,
						center: true,
						cover: false,
					},
					padding: "0px",
					hideDesktop: false,
					hideMobile: false,
					noStackMobile: false,
					_meta: {
						htmlClassNames: "u_row",
					},
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
				},
			},
			displayMode: "email",
		},
	];
	console.log(template);
	const [templateName, setTemplateName] = useState("");
	const emailEditorRef = useRef(null);
	console.log(props.match.params.designid);
	const handleChange = (event) => {
		setTemplateName(event.target.value);
	};
	const exportHtml = () => {
		emailEditorRef.current.exportHtml((data) => {
			const user = sessionStorage.getItem("UserName");
			const { design, html } = data;
			SaveEmailTemplate(templateName, design, html, user);
		});
	};
	const onDesignLoad = (data) => {
		console.log("onDesignLoad", data);
	};

	const onLoad = () => {
		emailEditorRef.current.editor.addEventListener(
			"onDesignLoad",
			onDesignLoad
		);
		// emailEditorRef.init({blocks:headerblock});
		emailEditorRef.current.editor.loadDesign(template[0].emailJson);
		emailEditorRef.current.editor.blocks = headerblock;
		// emailEditorRef.current.editor.init({
		//     id: 1,
		//     name: 'John Doe',
		//     email: 'john.doe@acme.com'
		//   });
		emailEditorRef.current.editor.setMergeTags({
			first_name: {
				name: "First Name",
				value: "{{FirstName}}",
				sample: "Kristin",
			},
			last_name: {
				name: "Last Name",
				value: "{{LastName}}",
				sample: "Boggs",
			},
			honorific: {
				name: "Honorific",
				value: "{{Honorific}}",
				sample: "The Honorable",
			},
			organization: {
				name: "Organization",
				value: "{{Organization}}",
				sample: "The Ohio House of Representatives",
			},
			salutation: {
				name: "Salutation",
				value: "{{Salutation}}",
				sample: "Representative",
			},
			contact_title: {
				name: "Title",
				value: "{{Title}}",
				sample: "State Representative",
			},
			rsvpdeadline: {
				name: "RSVP Deadline (current default is 7 days prior to event)",
				value: "{{RSVPdeadline}}",
				sample: "11/2/2019",
      },
      currentdate:{
        name: "CurrentDate",
        value: "{{CurrentDate}}",
        sample: "10/21/2020"
      },
			event_name: {
				name: "Event Name (for Request)",
				value: "{{EventName}}",
				sample: "Ohio State v. Maryland",
			},
			event_date: {
				name: "Event Date (for Request)",
				value: "{{EventDate}}",
				sample: "11/9/2019",
      },
      address:{
        name: "Mailing Address",
        value: "{{MailingAddress}}",
        sample: "77 South High Street"
      },
      citystate:{
        name: "Address",
        value: "{{Address}}",
        sample: "Columbus, OH 43215"
      }
		});
		//	'{first_name: {name: "First Name",value: "{{first_name}}",sample: "John",},last_name: {name: "Last Name",value: "{{last_name}}",sample: "Doe"}}'); //(template[0].emailJson);
	};
	if (template) {
		return (
			<Container>
				<Form.Input
					label="Template Name"
					placeholder="Choose a descriptive name for this email template"
					type="text"
					className="form-control"
					id="TemplateName"
					value={templateName}
					onChange={handleChange}
				/>
				<Bar>
					<h1>React Email Editor (Demo)</h1>

					<Link to={`/dashboard`}>Dashboard</Link>
					{/* <button onClick={saveDesign}>Save Design</button> */}
					<button onClick={exportHtml}>Save Design</button>
				</Bar>

				<EmailEditor ref={emailEditorRef} onLoad={onLoad} />
			</Container>
		);
	} else {
		return <div></div>;
	}
};

export default DesignEdit;
