import { SET_USER, USER_STATUS, ADD_ITEM } from "../actions-index";
import { isEmpty } from "lodash";

export function authentication(state = {}, action = {}) {
	const { payload } = action;
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				user: payload,
				status: !isEmpty(payload) ? "success" : state.status,
				

			};
		case USER_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case ADD_ITEM:
			return {
				...state,
				seasonlistchecked: action.payload,
			};
		default:
			return state;
	}
}
